
$color-blue-primary : #080549 !default;
$color-blue-secondary : #3A5DAB !default;

$color-grey-primary : #E0E7E7 !default;
$color-grey-secondary : #F8F8F8 !default;

$color-green-primary : #50E3C2 !default;
$color-green-secondary : #50E3C2 !default;

$screen__s: 800;
$screen__xxs : 320px !default;
$screen__xs  : 480px !default;
$screen__s   : 640px !default;
$screen__m   : 768px !default;
$screen__l   : 1024px !default;
$screen__xl  : 1440px !default;


@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,100;1,300&display=swap');

@mixin box-shadow($top, $left, $blur, $size, $color) {
   -webkit-box-shadow: $top $left $blur $size $color;
   -moz-box-shadow: $top $left $blur $size $color;
   box-shadow: $top $left $blur $size $color;
}

@import 'extend/global';
@import 'extend/header';
@import 'extend/footer';

@import 'extend/homepage';

@import 'extend/product';
@import 'extend/custom_gobo';
@import 'extend/category';
@import 'extend/mirasvit-search';


